/* You can add global styles to this file, and also import other style files */

@import "../node_modules/normalize.css/normalize.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

:root {
  --foreground-default: #08090a;
  --primary: red;
}

// $purple: #8a4d76;
// $pink: #fa7c91;
// $brown: #757763;
// $beige-light: #d0d1cd;
// $beige-lighter: #eff0eb;

// $color-primary: white;
// $color-secondary: #7cf9ff;
// $color-terziary: #363c45;

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
  font-family: "Lato", sans-serif;
}

body {
  background: linear-gradient(to bottom right, #50a3a2 0%, #00e1ff 100%);
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #00e1ff;
}
